/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Kierowca samochodu ciężarowego")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Siedziba firmy:"), " Łojewo, woj. kujawsko-pomorskie"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Miejsce pracy: Teren kraju - budowy prowadzone przez Pracodawcę")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Wymagania:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "uprawnienia kat. C / C+E,"), "\n", React.createElement(_components.li, null, "karta kierowcy,"), "\n", React.createElement(_components.li, null, "doświadczenie w przewożeniu kruszyw drogowych."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Zadania na stanowisku:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wykonywanie zleconych przewozów,"), "\n", React.createElement(_components.li, null, "dbałość o powierzone auto,"), "\n", React.createElement(_components.li, null, "współpraca z zespołem realizującym projekt."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Oferujemy:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "zatrudnienie w oparciu o umowę o pracę lub kontrakt b2b,"), "\n", React.createElement(_components.li, null, "możliwość dalszego rozwoju zawodowego,"), "\n", React.createElement(_components.li, null, "prywatną opiekę medyczną oraz ubezpieczenie na życie."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
